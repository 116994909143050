@charset "utf-8";

/*!
 * Theme Name: izawa-seito theme
 * Theme URI: http://izawa-seito.com/
 * Description: いざわせいとう専用のベーステーマ
 * Author: Shinichi Shimomura
 * Url: https://5ve.jp/
 * Version: 1.0
 */



/* アセット関連
--------------------------------------------------*/
@import './assets/setting';
@import './assets/tool/mixin';
@import './assets/module/normalize';
@import './assets/module/font';
@import './assets/module/base';





/* ライブラリ関連
--------------------------------------------------*/
@import './libs/owl.carousel';
@import './libs/owl.theme.default';



/* フォント
--------------------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Dosis:400,600');


/* 共通要素
--------------------------------------------------*/
html {

}

body {
  background-color: #fff;
}

.wrapper {
  min-width: 1100px;
  font-family: 'Dosis', sans-serif;
}
.sp .wrapper {
  width: 100%;
  min-width: unset;
}

.cover_image {
  position: relative;
  width: 100%;
  height: 66.66vw;
  min-height: 733.33px;
  margin: 0 0 130px 0;
}
.sp .cover_image {
  width: 100%;
  height: 100vh;
  margin: 0 0 55px 0;
  overflow: hidden;
}

.cover_image img {
  width: 100%;
  height: auto;
}
.sp .cover_image img {
  width: auto;
  height: 100%;
}

.container {
	width: 1000px;
	margin: 0 auto;
}
.sp .container {
  width: 100%;
}

.page_title {
  margin: 0 0 44px 0;
  text-align: center;
  font-size: 43px;
  letter-spacing: 0.0239em;
  line-height: 0.67;
}
.sp .page_title {
  margin: 0 0 23px 0;
  font-size: 23px;
  letter-spacing: 0.013em;
  line-height: 0.63;
}

.summary p {
  font-size: 23px;
  letter-spacing: 0.028em;
  line-height: 1.26;
}
.sp .summary p {
  padding: 0 15px;
  font-size: 16px;
  letter-spacing: 0.008em;
  line-height: 1.09;
}


/* HEADER
--------------------------------------------------*/

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 66.66vw;
  min-width: 1100px;
  min-height: 733.33px;
  z-index: 9000;
}
.sp header {
  height: 100vh;
  min-width: unset;
  min-height: unset;
}

header .gnav_container {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 15px;
  background-color: rgba(0,0,0,0.5);
  z-index: 2000;
}

header .gnav_container li {
  float: left;
  margin-left: 10px;
}
header .gnav_container li:first-child {
  margin-left: 0;
}

header .menu {
  display: block;
  position: absolute;
  top: calc(100% + 30px);
  right: 30px;
  width: 85px;
  height: 41px;
}
header .menu:hover {
  opacity: 0.4;
}
.sp header .menu {
  position: absolute;
  top: calc(100vh + 10px);
  right: 10px;
  width: 52.5px;
  height: 30px;
}

header .menu .menu_bar {
  left: 10px;
  width: 65px;
}
.sp header .menu .menu_bar {
  width: 32.5px;
  height: 1px;
}

header .menu .menu_bar:nth-of-type(1) {
  top: 10px;
}
header .menu .menu_bar:nth-of-type(2) {
  display: none;
}
header .menu .menu_bar:nth-of-type(3) {
  bottom: 10px;
}
.sp header .menu .menu_bar:nth-of-type(3) {
  top: 20px;
}

header .qtranslate-chooser {
  display: block;
  position: absolute;
  top: calc(100% + 30px);
  right: 90px;
  width: 85px;
  height: 41px;
}


/* TOP PAGE
--------------------------------------------------*/
.top_page {

}

.top_page .logo {
  position: absolute;
  top: 46.8%;
  left: 13.6%;
  width: 169px;
  height: auto;
}
.sp .top_page .logo {
  width: 133px;
  height: auto;
  top: unset;
  right: 10.13%;
  bottom: 6.52%;
  left: unset;
}

.top_page .logo a:hover {
  opacity: 0.6;
}

.top_page .logo img {
  width: 100%;
  height: auto;
}

.top_page .summary p {
  margin: 0 0 51px 0;
}
.sp .top_page .summary p {
  margin: 0 0 18.5px 0;
}

.top_page .summary a {
  display: block;
  width: 176px;
  height: 60px;
  margin: 0 auto 88px auto;
  text-align: center;
  font-size: 20px;
  letter-spacing: 0.01em;
  line-height: 57px;
  border: 1px solid #000;
  transition: 0.2s all;
}
.sp .top_page .summary a {
  width: 122px;
  height: 43px;
  margin: 0 auto 36.5px auto;
  font-size: 16px;
  letter-spacing: 0.09em;
  line-height: 40px;
}
.top_page .summary a:hover {
  color: #999;
}

.top_page .products {
  margin: 0 0 58px 0;
}
.sp .top_page .products {
  width: 100%;
  height: auto;
  margin: 0 0 40px 0;
}

.top_page .products li {
  position: relative;
  width: 50%;
  height: auto;
  float: left;
  overflow: hidden;
}
.sp .top_page .products li {
  width: 100%;
  height: auto;
  float: none;
}
.top_page .products li:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 9px solid #F69256;
  transform: scale(1.1, 1.1);
  transition: transform 0.2s;
}
.sp .top_page .products li:after {
  height: 100vw;
}
.top_page .products li:hover:after {
  transform: scale(1,1);
  cursor: pointer;
}

.top_page .products .mask {
  width: 100%;
  height: 50%;
}
.sp .top_page .products .mask {
  height: 100vw;
}
.top_page .products .mask:hover {
  border: 9px solid #F69256;
}

.top_page .products li img {
  width: 100%;
  height: auto;
}

.top_page .products li h3 {
  position: absolute;
  top: 11%;
  left: 50%;
  text-align: center;
  font-size: 43px;
  letter-spacing: 0.012em;
  line-height: 1.25;
  transform: translateX(-50%);
}
.sp .top_page .products li h3 {
  top: 8.9%;
  font-size: 21.5px;
  letter-spacing: 0.009em;
  line-height: 0.62;
}

.top_page .factory {
  position: relative;
}

.top_page .factory .mask {
  width: 100%;
  height: 50%;
}
.sp .top_page .factory .mask {
  height: 66.57vw;
}

.top_page .factory div img {
  width: 100%;
  height: auto;
  transition: all 0.4s ease;
}
.top_page .factory div img:hover {
  opacity: 0.9;
}

.top_page .factory h3 {
  position: absolute;
  top: 9%;
  left: calc(50% - 79.015px);
  font-size: 43px;
  letter-spacing: 0.02em;
  line-height: 1.25;
  color: #fff;
}
.sp .top_page .factory h3 {
  top: 13.41%;
  left: 50%;
  font-size: 21.5px;
  letter-spacing: 0.009em;
  line-height: 1.25;
  transform: translateX(-50%);
}
.sp .top_page .products li:last-child h3 {
  color: #fff;
}

/* PRODUCTS-SINGLE
--------------------------------------------------*/

.products_single {

}

.products_single .summary .summary_about h3 {
  margin: 65px 0 31px 0;
  font-size: 32px;
  letter-spacing: 0.0178em;
  line-height: 0.9;
}

.products_single .summary .summary_about p:last-of-type {
  margin: 0 0 48px 0;
}

.products_single .summary .features h4 {
  margin: 0 0 31px 0;
  font-size: 32px;
  letter-spacing: 0.0178em;
  line-height: 0.9;
}

.products_single .summary .features {
  padding: 0 0 0 30px;
  border-left: 2px solid #D8D8D8;
}

.products_single .summary .features h4 {
  margin: 0 0 31px 0;
  font-size: 32px;
  letter-spacing: 0.0178em;
  line-height: 0.9;
}

.products_single .summary .features p {
  margin: 0 0 30px 0;
}

.products_single .summary .features p:last-child {
  margin: 0 0 76px 0;
}

.products_single .shop {
  margin: 0 0 129px 0;
}

.products_single .shop img {
  float: left;
  width: 50%;
  height: auto;
}

.products_single .shop .paypal {
  width: 50%;
  margin: 0 0 0 50%;
  padding: 0 58px;
}

.products_single .shop .paypal .name,
.products_single .shop .paypal .price {
  font-size: 24px;
  letter-spacing: 0.01em;
  line-height: 1.54;
  font-weight: normal;
}
.products_single .shop .paypal .name {
  margin: 0;
}
.products_single .shop .paypal .price {
  margin: 0 0 25px 0;
}

.products_single .shop .paypal .dimensions {
  font-size: 21px;
  letter-spacing: 0.01em;
  line-height: 1.28;
  color: #9B9B9B;
}
.products_single .shop .paypal .dimensions span {
  margin: 0 16px 0 0;
}

.products_single .shop .paypal form table td {
  border: none;
}

.products_single .shop .paypal .caution {
  font-size: 19px;
  letter-spacing: 0.01em;
  color: #9B9B9B;
}

.products_single .shop .paypal .caution p {
  margin: 0 0 27px 0;
  line-height: 1.31;
}

.products_single .gallery {

}

.products_single .gallery .column_container {
  column-count: 2;
  column-gap: 10px;
}

.products_single .gallery .item {
  float: left;
  width: 100% !important;
  height: auto;
  padding-bottom: 10px;
}

.products_single .gallery .item img {
  width: 100%;
  height: auto;
}


/* ABOUT PAGE
--------------------------------------------------*/
.about_page {

}

.about_page .page_title {
  margin: 0 0 50px 0;
}

.about_page .content p {
  font-size: 23px;
  letter-spacing: 0.0128em;
  line-height: 1.26;
  margin: 0 0 45px 0;
}
.about_page .content p:nth-of-type(2) {
  margin: 0 0 37px 0;
}
.about_page .content p:nth-of-type(3) {
  margin-bottom: 50px;
}
.about_page .content p:last-of-type {
  margin: 0 0 153px 0;
}


/* FACTORY PAGE
--------------------------------------------------*/
.factory_page {

}

.factory_page .page_title {
  margin: 0 0 130px 0;
}

.factory_page .content .address {
  margin: 0 0 160px 0;
  font-size: 23px;
  letter-spacing: 0.0128em;
  line-height: 1.26;
  font-style: normal;
}

.factory_page .content .about p {
  font-size: 23px;
  letter-spacing: 0.0128em;
  line-height: 1.26;
  margin: 0 0 45px 0;
}
.factory_page .content .about p:nth-of-type(2) {
  margin: 0 0 37px 0;
}
.factory_page .content .about p:nth-of-type(3) {
  margin: 0 0 50px 0;
}
.factory_page .content p:last-of-type {
  margin: 0 0 153px 0;
}


/* FOOTER
--------------------------------------------------*/
footer {

}

footer .footer_container {
  position: relative;
  width: 980px;
  margin: 0 auto;
}

footer .copyright {

}





/* WordPress関連
--------------------------------------------------*/
.current_nav,
.current-cat a {
  opacity: 0.6;
}

.wp-pagenavi {
  margin: 60px 0 100px 0;
}

.wp-pagenavi span,
.wp-pagenavi a {
  margin-left: 20px;
  padding: 10px 5px 5px 5px;
  font-size: 16px;
}
.wp-pagenavi span:first-child,
.wp-pagenavi a:first-child {
  margin-left: 0;
}

.wp-pagenavi .current {
  border-bottom: 1px solid #000000;
}

.controller {
  position: relative;
  width: 680px;
  height: 20px;
}
.sp .controller {
  width: 100%;
}

.controller span a:hover {
  opacity: 0.4;
}

.controller_prev {
  position: absolute;
  top: 0;
  left: 0;
}
.sp .controller_prev {
  position: static;
  top: auto;
  left: auto;
  margin-bottom: 20px;
  display: block;
}

.controller_prev a {
  padding-left: 20px;
  font-size: 14px;
  font-weight: bold;
  background-image: url(img/icon_prev.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 9px 18px;
}
.sp .controller_prev a {
  font-size: 12px;
}

.controller_next {
  position: absolute;
  top: 0;
  right: 0;
}
.sp .controller_next {
  position: static;
  top: auto;
  right: auto;
}

.controller_next a {
  padding-right: 20px;
  font-size: 14px;
  font-weight: bold;
  background-image: url('img/icon_next.svg');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 9px 18px;
}
.sp .controller_next a {
  padding-right: 0;
  padding-left: 20px;
  font-size: 12px;
  background-position: left center;
}

.controller_close a {
  display: inline-block;
  width: 44px;
  height: 44px;
  text-indent: -9999px;
  background-image: url('img/icon_close.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 17px 17px;
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	overflow: hidden;
	max-width: 100%;
	height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  margin-top: 3px;
  padding: 6px 10px 10px 10px;
  font-size: 16px;
  line-height: 1.75;
  color: #ffffff;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  background-color: #000000;
  -webkit-appearance: none;
}
textarea {
  margin-top: 30px;
  padding: 10px 10px 15px 10px;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

input[type="submit"] {
  display: block;
  width: 50%;
  margin: -20px auto 10px auto;
  padding: 11px 10px 9px 10px;
  font-size: 17px;
  font-family: 'Avenir Next LT W01 Demi';
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 0.0231em;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  background-color: #000000;
  -webkit-appearance: none;
}

span.wpcf7-not-valid-tip {
  margin-top: 5px;
  font-size: 12px !important;
}

div.wpcf7-validation-errors,
div.wpcf7-mail-sent-ok {
  padding: 0 !important;
  font-size: 13px;
  line-height: 1.75;
  border: none !important;
}

.acf-map {
  width: 100%;
  height: 100%;
}

/* fixes potential theme css conflict */
.acf-map img {
  max-width: inherit !important;
}
