@mixin link-color($color) {
	color: $color;
	&:hover,
	&:active,
	&:focus {
		color: lighten($color, 20%);
	}
}

@mixin opacity($opacity) {
	opacity: $opacity;
	filter: alpha(opacity=($opacity * 100));
}

